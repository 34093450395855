export const ADD_TO_CART = 'ADD_TO_CART';
export const DECREASE_QUANTITY = 'DECREASE_QUANTITY';
export const DELETE_FROM_CART = 'DELETE_FROM_CART';
export const DELETE_ALL_FROM_CART = 'DELETE_ALL_FROM_CART';
export const ADD_SMARTBOOK_TO_CART = 'ADD_SMARTBOOK_TO_CART';

// add to cart
export const addToCart = (
  item,
  addToast,
  quantityCount,
  selectedVariants,
) => (dispatch) => {
  if (addToast) {
    addToast('Añadido al carrito', { appearance: 'success', autoDismiss: true });
  }
  
  if(selectedVariants && selectedVariants.smartbook){
    dispatch({
      type: ADD_SMARTBOOK_TO_CART,
      payload: {
        ...item,
        quantity: quantityCount,
        smartbook: true,
        selectedBookSlastics: selectedVariants && selectedVariants.bookSlastics
          ? selectedVariants.bookSlastics.name : null,
        selectedStickers: selectedVariants && selectedVariants.stickers
          ? selectedVariants.stickers.name : null,
  
      },
    });
  }else{
    dispatch({
      type: ADD_TO_CART,
      payload: {
        ...item,
        quantity: quantityCount,
        selectedCoverColor: selectedVariants && selectedVariants.cover
          ? selectedVariants.cover.name
          : null,
        selectedBookTape: selectedVariants && selectedVariants.bookTapes
          ? selectedVariants.bookTapes.name : null,
        selectedBookSlastics: selectedVariants && selectedVariants.bookSlastics
          ? selectedVariants.bookSlastics.name : null,
        selectedStickers: selectedVariants && selectedVariants.stickers
          ? selectedVariants.stickers.name : null,
        selectedSheetType: selectedVariants && selectedVariants.sheetTypes
          ? selectedVariants.sheetTypes.name : null,
          genericVariant: selectedVariants && selectedVariants.genericVariant
          ? selectedVariants.genericVariant.name : null,
        customBook: !!(selectedVariants && selectedVariants.customBook),
        metallicCorners: !!(selectedVariants && selectedVariants.metallicCorners),
  
      },
    });
  }
};
// decrease from cart
export const decreaseQuantity = (item, addToast) => (dispatch) => {
  if (addToast) {
    addToast('Se elimino el producto del carrito', {
      appearance: 'warning',
      autoDismiss: true,
    });
  }
  dispatch({ type: DECREASE_QUANTITY, payload: item });
};
// delete from cart
export const deleteFromCart = (item, addToast) => (dispatch) => {
  if (addToast) {
    addToast('Se elimino el producto del carrito', { appearance: 'error', autoDismiss: true });
  }
  dispatch({ type: DELETE_FROM_CART, payload: item });
};
// delete all from cart
export const deleteAllFromCart = (addToast) => (dispatch) => {
  if (addToast) {
    addToast('Se eliminarón todos los productos del carrito', {
      appearance: 'error',
      autoDismiss: true,
    });
  }
  dispatch({ type: DELETE_ALL_FROM_CART });
};

// get stock of cart item
export const cartItemStock = (item, color, size) => {
  if (item.stock) {
    return item.stock;
  }
  if (item.variation) {
    return item.variation
      .filter((single) => single.color === color)[0]
      .size.filter((single) => single.name === size)[0].stock;
  }
  return 5000;
};
